<dpa-modal
  [modalOpen]="isConfirmLeaveModalOpen$ | async"
  [modalSize]="ModalSize.MD"
  (onModalClose)="cancelNavigateAway()"
>
  <dpa-modal-title>
    {{ 'WIZARD.DISCARD_CHANGES' | translate }}
  </dpa-modal-title>
  <div class="modal-body">
    {{ 'WIZARD.DISCARD_UNSAVED_DATA' | translate }}
    @if (pendingMessage$ | async; as pendingMessage) {
      <p class="p7 pending-message">
        {{ pendingMessage }}
      </p>
    }
  </div>
  <div class="modal-footer">
    <button
      (click)="cancelNavigateAway()"
      class="btn btn-outline"
    >
      {{ 'FILTER_VALUE.NO' | translate }}
    </button>
    <button
      (click)="confirmNavigateAway()"
      class="btn btn-primary"
    >
      {{ 'FILTER_VALUE.YES' | translate }}
    </button>
  </div>
</dpa-modal>
