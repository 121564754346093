@if (navGroupHeading) {
  <clr-vertical-nav-group
    [clrVerticalNavGroupExpanded]="!isSecondaryNavCollapsed && !isGroupCollapsed"
    [class.active]="isGroupActive && (isSecondaryNavCollapsed || isGroupCollapsed)"
    (click)="onNavGroupLabelClick($event)"
  >
    <dpa-tooltip
      clrVerticalNavIcon
      [tooltipText]="navGroupHeading"
      [attr.aria-label]="navGroupHeading"
      [focusEnabled]="true"
      class="display-flex"
      role="tooltip"
    >
      @if (navGroupIconName) {
        <cds-icon [attr.shape]="navGroupIconName" />
      } @else {
        <div [ngClass]="[navGroupIconClass, 'nav-group-icon']"></div>
      }
    </dpa-tooltip>
    <dpa-ellipsis-with-tooltip>
      {{ navGroupHeading }}
    </dpa-ellipsis-with-tooltip>
    <clr-vertical-nav-group-children>
      <ng-container *ngTemplateOutlet="navItems" />
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>
} @else {
  <ng-container *ngTemplateOutlet="navItems" />
}

<ng-template #navItems>
  <ng-container [ngTemplateOutlet]="navItemsTemplate || defaultNavItemsTemplate" />
</ng-template>

<ng-template #defaultNavItemsTemplate>
  @for (menuItem of menuItems; track menuItem) {
    <dpa-tooltip
      *ngLet="menuItem.labelKey | translate as label"
      [tooltipText]="label"
      [attr.aria-label]="label"
      [focusEnabled]="true"
      role="tooltip"
    >
      <a
        clrVerticalNavLink
        #menuRoute="routerLinkActive"
        [routerLink]="[menuItem.route]"
        [class.active-nav]="isSideNavMenuActive(menuItem.route)"
        routerLinkActive="active"
        class="nav-link"
      >
        @if (menuItem.iconClass) {
          <div
            clrVerticalNavIcon
            [ngClass]="[menuItem.iconClass, 'menu-item-icon']"
            [class.selected]="menuRoute.isActive"
          ></div>
        }
        @if (menuItem.iconName) {
          <cds-icon
            clrVerticalNavIcon
            [attr.shape]="menuItem.iconName"
            [class.selected]="menuRoute.isActive"
            class="menu-item-icon"
          />
        }
        <div class="menu-item-content">
          <dpa-ellipsis-with-tooltip [class.bold]="menuItem.bold">
            {{ label }}
          </dpa-ellipsis-with-tooltip>
          @if (menuItem.hasBetaFlag) {
            <div class="ml-x1 beta-flag">
              {{ 'COMMON_MESSAGES.BETA' | translate }}
            </div>
          }
        </div>
      </a>
    </dpa-tooltip>
  }
</ng-template>
