<dpa-global-header [showHeaderActions]="isAuthenticated$ | async">
  <div
    dpa-global-header-branding
    class="branding-wrapper"
  >
    <ng-container
      [ngTemplateOutlet]="brandingTemplate"
      [ngTemplateOutletContext]="{ isRemediationMode: isRemediationMode$ | async }"
    />
  </div>
  <div
    dpa-global-header-search
    class="search-wrapper"
  >
    @if (showGlobalSearch$ | async) {
      <dpa-global-header-search class="clr-hidden-xs-down" />
    }
  </div>
  @if (showNotificationsHeaderAction$ | async) {
    <dpa-global-header-panel-notifications
      [notifications]="notifications$ | async"
      [isLoading]="isLoadingNotifications$ | async"
      [unreadNotificationsCount]="unreadNotificationsCount$ | async"
      [subheaderTemplate]="subheaderTemplate"
      [headerActionsTemplate]="notificationsHeaderActionTemplate"
      (dismissNotification)="onDismiss($event)"
      (toggleReadNotification)="toggleRead($event)"
      (markAllAsRead)="markAllAsRead($event)"
      (dismissAll)="onDismissAll($event)"
      (viewNotification)="onViewNotification($event)"
      (clickNotificationAction)="onNotificationAction($event)"
      (activatePanel)="loadNotifications()"
    />
  }
  @if (showBaseHeaderActions$ | async) {
    @if (isOnboardingComplete$ | async) {
      <dpa-global-header-panel-user
        [activeUser]="activeUser$ | async"
        [activeOrg]="activeOrg$ | async"
        [organizationRoute]="ROUTE_NAMES.ORGANIZATION"
        [userProfileRoute]="ROUTE_NAMES.PROFILE"
        [availableOrgs]="userOrgs$ | async"
        (orgChange)="changeOrg($event)"
        (logout)="onLogout()"
      />
    }
    <dpa-global-header-panel-services
      [serviceLinks]="serviceLinks$ | async"
      [isCspLoggedIn]="isCspLoggedInUser$ | async"
      [cspBaseUrl]="orgBaseUrl$ | async"
    />
  }
</dpa-global-header>

<ng-template #subheaderTemplate>
  <div class="sort-container p-x1 pl-x2 pr-x3">
    <div class="sort-dropdown">
      <clr-dropdown>
        <button
          clrDropdownTrigger
          [attr.aria-label]="'COMMON_ACTIONS.CARD_OPTIONS' | translate"
          class="btn btn-link btn-sm sort-notification"
        >
          <cds-icon
            shape="sort-by"
            class="sort-by-icon"
          />
          <span class="pl-x1">
            {{ 'COMMON_MESSAGES.SORT_BY' | translate }}
          </span>
        </button>
        <clr-dropdown-menu
          *clrIfOpen
          [clrPosition]="CLARITY_TOOLTIP_POSITION.BOTTOM_RIGHT"
        >
          @for (sortItem of sortByList; track sortItem) {
            <button
              clrDropdownItem
              (click)="onSortChange(sortItem)"
            >
              {{ sortItem.label }}
            </button>
          }
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </div>
</ng-template>

<ng-template #notificationsHeaderActionTemplate>
  <div class="mr-x2">
    <button
      [routerLink]="[ROUTE_NAMES.NOTIFICATION.HOME]"
      class="btn btn-inverse btn-sm"
    >
      {{ 'COMMON_ACTIONS.VIEW_ALL' | translate }}
    </button>
    @if (isRulesEnabled$ | async) {
      <button
        [routerLink]="[ROUTE_NAMES.NOTIFICATION.SETTINGS]"
        class="btn btn-link btn-icon mv-0"
      >
        <cds-icon
          class="settings-icon"
          shape="cog"
        />
      </button>
    }
  </div>
</ng-template>
