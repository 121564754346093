<dpa-common-menu
  [defaultMenuItemRoute]="defaultMarketplaceMenuItemRoute$ | async"
  [menuItems]="rootMenuItems$ | async"
/>
<dpa-common-menu
  [menuItems]="integrationsMenuItems$ | async"
  [navGroup]="NavigationMenuGroup.MARKETPLACE_INTEGRATIONS"
  [navGroupHeading]="'NAVIGATION.INTEGRATIONS' | translate"
  [navGroupIconName]="'connect'"
/>
<dpa-common-menu
  [menuItems]="solutionsMenuItems$ | async"
  [navGroup]="NavigationMenuGroup.MARKETPLACE_SOLUTIONS"
  [navGroupHeading]="'NAVIGATION.INTELLIGENCE_HEADER.SOLUTIONS' | translate"
  [navGroupIconName]="'nodes'"
/>
<dpa-common-menu
  [menuItems]="templatesMenuItems$ | async"
  [navGroup]="NavigationMenuGroup.MARKETPLACE_TEMPLATES"
  [navGroupHeading]="'NAVIGATION.INTELLIGENCE_HEADER.TEMPLATES' | translate"
  [navGroupIconName]="'file-group'"
/>
