<div
  dpaScrollbars
  [useFlexbox]="true"
  class="maintenance-container pl-x4 pr-x4"
>
  <h1 class="mt-x8 mb-x8">
    {{ 'MAINTENANCE.TITLE' | translate }}
  </h1>
  <div class="maintenance"></div>
  <div class="maintenance-text">
    <h5>
      {{ 'MAINTENANCE.DESCRIPTION_1' | translate }}
      <br />
      {{ 'MAINTENANCE.DESCRIPTION_2' | translate }}
    </h5>
  </div>
</div>
