<clr-main-container
  [ngClass]="{ 'apteligent-mode': isStandaloneApteligentEnabled$ | async, 'ws-one-cloud-mode': isWorkspaceOneCloudOrg }"
  class="app-view"
>
  <dpa-tooltip-view />
  <dpa-alert-banner [target]="ALERT_BANNER_TARGET.APP" />
  @if (hideTrialBanner === false) {
    <dpa-trial-banner />
  }
  @if ((isNotificationEnabled$ | async) && (hasUserScopes$ | async)) {
    <dpa-banner-notification />
  }
  @if (showCookiePolicyBanner$ | async) {
    <dpa-cookie-policy-banner />
  }
  @if ((isNavigationRestricted$ | async) === false) {
    <clr-header>
      <dpa-header [brandingTemplate]="isWorkspaceOneCloudOrg ? wsOneCloudBranding : wsOneIntelBranding" />
    </clr-header>
  }
  <div
    #contentContainer
    class="full-page-container"
  >
    @if ((isNavigationRestricted$ | async) === false) {
      <dpa-navigation-menu />
    }
    <div class="content-container">
      @if (showSecondaryNavigation$ | async) {
        <nav class="sidenav">
          <dpa-secondary-navigation-menu />
        </nav>
      }
      <main
        [class.banner-visible]="banner.isVisible"
        class="content-area"
        aria-label="main"
        role="main"
      >
        <dpa-alert-banner
          #banner
          [target]="ALERT_BANNER_TARGET.PAGE"
        />
        @if (isQueryAIEnabled && !hideOmniWidget) {
          <dpa-omni-widget />
        }
        @if (isBrownfieldNotificationModalShown$ | async) {
          <dpa-brownfield-notification-modal />
        }
        @if (isRequestCloudAccountModalOpen$ | async) {
          <dpa-request-cloud-account-modal />
        }
        @if (hasUserScopes$ | async) {
          <dpa-intro-modal />
        }
        <router-outlet />
      </main>
      <dpa-navigation-preview-modal />
      <dpa-active-widget-copy-modal />
      <!-- keep this at the end, this modal can appear on top of other modals -->
      <dpa-navigation-confirm-modal />
    </div>
  </div>
</clr-main-container>

<div class="main-container maintenance-view">
  <header class="header">
    <div class="branding branding-wrapper">
      <ng-template
        [ngTemplateOutlet]="isWorkspaceOneCloudOrg ? wsOneCloudBranding : wsOneIntelBranding"
        [ngTemplateOutletContext]="{ isRemediationMode: isRemediationMode$ | async }"
      />
    </div>
  </header>
  <div class="content-container">
    <main
      class="content-area"
      aria-label="main"
      role="main"
    >
      <dpa-maintenance />
    </main>
  </div>
</div>

<ng-template
  #wsOneIntelBranding
  let-isRemediationMode
>
  <div
    [class.is-remediation-mode]="isRemediationMode"
    class="ws-intel-logo-container"
  >
    <div
      [class.is-remediation-mode]="isRemediationMode"
      class="ws-intel-logo"
    ></div>
  </div>
  <div class="ws-intel-text-container clr-hidden-sm-down">
    <div
      [class.is-remediation-mode]="isRemediationMode"
      class="ws-intel-text"
    ></div>
  </div>
</ng-template>

<ng-template
  #wsOneCloudBranding
  let-isRemediationMode
>
  <div
    [class.is-remediation-mode]="isRemediationMode"
    class="workspace-one-logo-container"
  >
    <div class="workspace-one-logo"></div>
  </div>
</ng-template>

@if (isNonAdminMode$ | async) {
  <dpa-user-session />
}
