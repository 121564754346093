@if (introModalOpen$ | async) {
  <dpa-modal
    [modalOpen]="introModalOpen$ | async"
    [modalClosable]="true"
    [modalSize]="ModalSize.XL"
    (onModalClose)="closeIntroModal()"
  >
    @if (introModalMode === INTRO_MODAL_MODE.ACTIVATE_TRIAL) {
      <dpa-modal-title class="clr-flex-items-sm-middle">
        {{ 'INTRO_MODAL.WELCOME_TO_WS1_INTELLEGENCE' | translate }}
      </dpa-modal-title>
    }
    <div class="modal-body">
      @if (!isFetchingTrialUserContactDetails) {
        @switch (introModalMode) {
          @case (INTRO_MODAL_MODE.ACTIVATE_TRIAL) {
            <dpa-start-trial
              [trialUserContactDetails]="trialUserContactDetails$ | async"
              (onFormInit)="onTrialFormInit($event)"
            />
          }
          @case (INTRO_MODAL_MODE.ACTIVATING_TRIAL) {
            <div class="activating-trial-wrapper">
              <div class="trial-animation">
                <div
                  dpaAnimateSvg
                  [animateOptions]="trialAnimationConfig"
                  class="animation-container"
                ></div>
              </div>
              <p class="mt-x0">
                {{ 'TRIAL.ACTIVATING_WORKSPACE_ONE_INTELLIGENCE_TRIAL' | translate }}
              </p>
            </div>
          }
          @case (INTRO_MODAL_MODE.ACTIVATED_TRIAL) {
            <div class="activated-trial-wrapper">
              <cds-icon
                class="is-solid success"
                shape="check-circle"
                size="80"
              />
              <p class="mt-x8">
                {{ 'TRIAL.WORKSPACE_ONE_INTELLIGENCE_TRIAL_ACTIVATED_DESCRIPTION' | translate }}
              </p>
              <button
                (click)="closeIntroModal()"
                class="btn btn-primary mt-x9 get-started-btn"
              >
                {{ 'COMMON_ACTIONS.GET_STARTED' | translate }}
              </button>
            </div>
          }
        }
      } @else {
        <div class="spinner-container-centered">
          <div class="spinner"></div>
        </div>
      }
    </div>
    @if (!isFetchingTrialUserContactDetails && introModalMode === INTRO_MODAL_MODE.ACTIVATE_TRIAL) {
      <div class="modal-footer">
        <button
          (click)="closeIntroModal()"
          class="btn btn-outline"
        >
          {{ 'TRIAL_BANNER.NO_THANKS' | translate }}
        </button>
        <button
          [disabled]="isPrimaryTrialButtonDisabled()"
          (click)="primaryTrialSubmit()"
          class="btn btn-primary"
        >
          {{ 'COMMON_ACTIONS.ACCEPT' | translate }}
        </button>
      </div>
    }
  </dpa-modal>
}
