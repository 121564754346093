<clr-alerts>
  @for (notification of notifications$ | async; track notification) {
    <clr-alert
      [clrAlertClosable]="notification.dismissable"
      [clrAlertAppLevel]="true"
      [clrAlertType]="SEVERITY_ALERT_TYPE_MAPPING[notification.severity]"
      (clrAlertClosedChange)="dismissAlert(notification.id)"
    >
      <div
        [ngClass]="SEVERITY_ALERT_TYPE_MAPPING[notification.severity]"
        class="service-icon-wrpr"
      >
        <div
          [attr.data-service-type]="notification?.serviceType | uppercase"
          class="notification-service-icon white"
        ></div>
      </div>
      <clr-alert-item>
        {{ notification.body }}
        @if (notification.actionTitle && (notification.actionUrl || notification.actionPage)) {
          <div class="ml-x2">
            <button
              (click)="onActionClick(notification)"
              class="btn btn-sm"
            >
              {{ notification.actionTitle }}
              @if (notification.actionUrl) {
                <cds-icon
                  shape="pop-out"
                  size="12"
                  class="ml-x1"
                />
              }
            </button>
          </div>
        }
      </clr-alert-item>
    </clr-alert>
  }
</clr-alerts>
