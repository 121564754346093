<div class="toggle-box mt-2 pl-4 pv-1">
  <clr-toggle-wrapper>
    <input
      clrToggle
      [checked]="isUemV2DashboardToggleEnabledInUI"
      (change)="onToggleClick($event)"
      type="checkbox"
    />
    <label>{{ 'COMMON_MESSAGES.SWITCH_TO_UEM_V2' | translate }}</label>
  </clr-toggle-wrapper>
</div>
