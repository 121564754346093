<nav
  [clr-nav-level]="1"
  class="subnav"
  role="navigation"
>
  <ul class="nav">
    @for (menuItem of navMenuItems$ | async; track menuItem) {
      <li class="nav-item">
        <a
          [routerLink]="[navUrlByMenuItem[menuItem]]"
          [attr.label]="labelKeyByNavMenuItem[menuItem] | translate"
          class="nav-link"
          routerLinkActive="active"
        >
          {{ labelKeyByNavMenuItem[menuItem] | translate }}
        </a>
      </li>
    }
  </ul>
</nav>
