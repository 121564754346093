@if (isPreviewModalOpen$ | async; as isPreviewModalOpen) {
  <dpa-modal
    [modalOpen]="isPreviewModalOpen"
    [modalSize]="ModalSize.XL"
    (onModalClose)="closeModal()"
  >
    <dpa-modal-title>
      {{ previewModalTitle$ | async }}
    </dpa-modal-title>
    <div class="modal-body">
      <dpa-lazy-container
        [componentType]="this.componentType"
        [props]="props"
      />
    </div>
    <div class="modal-footer">
      <button
        (click)="closeModal()"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CANCEL' | translate }}
      </button>
      <button
        (click)="navigateToRoute(buttonData?.secondary.route)"
        class="btn btn-outline"
      >
        {{ buttonData?.secondary.text | translate }}
      </button>
      <button
        (click)="navigateToRoute(buttonData?.primary.route)"
        class="btn btn-primary"
      >
        {{ buttonData?.primary.text | translate }}
      </button>
    </div>
  </dpa-modal>
}
